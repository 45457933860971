var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "changeWage" },
    [
      _c(
        "Modal",
        {
          attrs: {
            "overflow-auto": "",
            visible: _vm.visible,
            closable: "",
            title: "人员定薪",
            size: "normal",
          },
          on: {
            ok: _vm.handleOk,
            cancel: function ($event) {
              _vm.visible = false
            },
          },
        },
        [
          _c("div", { staticClass: "infoBox" }, [
            _c("div", { staticClass: "infoItem" }, [
              _c("div", { staticClass: "label" }, [_vm._v("员工姓名")]),
              _c("div", { staticClass: "value" }, [
                _vm._v(_vm._s(_vm.info.staffName)),
              ]),
            ]),
            _c("div", { staticClass: "infoItem" }, [
              _c("div", { staticClass: "label" }, [_vm._v("员工状态")]),
              _c("div", { staticClass: "value" }, [
                _vm._v(_vm._s(_vm.info.staffStatusName)),
              ]),
            ]),
            _c("div", { staticClass: "infoItem" }, [
              _c("div", { staticClass: "label" }, [_vm._v("转正日期")]),
              _c("div", { staticClass: "value" }, [
                _vm._v(_vm._s(_vm.info.formalDate)),
              ]),
            ]),
            _c("div", { staticClass: "infoItem" }, [
              _c("div", { staticClass: "label" }, [_vm._v("定薪生效日期")]),
              _c("div", { staticClass: "value" }, [
                _vm._v(_vm._s(_vm.info.setEffectiveDate)),
              ]),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "changeBox" },
            [
              _vm._l(_vm.salaryList, function (item, index) {
                return [
                  _c(
                    "div",
                    {
                      key: "changeItem" + index,
                      staticClass: "changeItem fixed",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "formal" },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "name",
                              attrs: { title: item.formal.name },
                            },
                            [_vm._v(_vm._s(item.formal.name))]
                          ),
                          _c("NumThousand", {
                            staticClass: "value",
                            attrs: {
                              "v-model": item.formal.amount,
                              suffix: "元",
                              "is-thousands": true,
                              "decimal-separator": "",
                              "is-fill-zero": "",
                              precision: 2,
                            },
                            on: {
                              blur: function ($event) {
                                return _vm.echoValFun(
                                  arguments[0],
                                  item.probation.proportion,
                                  item,
                                  index
                                )
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _vm.isShowProbation
                        ? _c("div", { staticClass: "probation" }, [
                            _c(
                              "div",
                              {
                                staticClass: "name",
                                attrs: { title: item.probation.name },
                              },
                              [_vm._v(_vm._s(item.probation.name))]
                            ),
                            _c(
                              "div",
                              { staticClass: "content" },
                              [
                                _c(
                                  "a-radio-group",
                                  {
                                    staticClass: "way",
                                    on: {
                                      change: function ($event) {
                                        var i = arguments.length,
                                          argsArray = Array(i)
                                        while (i--) argsArray[i] = arguments[i]
                                        return _vm.handleChange.apply(
                                          void 0,
                                          argsArray.concat([item])
                                        )
                                      },
                                    },
                                    model: {
                                      value: item.probation.way,
                                      callback: function ($$v) {
                                        _vm.$set(item.probation, "way", $$v)
                                      },
                                      expression: "item.probation.way",
                                    },
                                  },
                                  [
                                    _c(
                                      "a-radio",
                                      { attrs: { value: "proportion" } },
                                      [_vm._v(" 薪资比例 ")]
                                    ),
                                    _c("a-radio", { attrs: { value: "num" } }, [
                                      _vm._v(" 薪资数值 "),
                                    ]),
                                  ],
                                  1
                                ),
                                item.probation.way === "proportion"
                                  ? _c("NumberInt", {
                                      staticClass: "proportion",
                                      attrs: { max: 100, min: 1, suffix: "%" },
                                      on: {
                                        blur: function ($event) {
                                          return _vm.proportionChange(
                                            item.formal.amount,
                                            item.probation.proportion,
                                            item
                                          )
                                        },
                                      },
                                      model: {
                                        value: item.probation.proportion,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            item.probation,
                                            "proportion",
                                            $$v
                                          )
                                        },
                                        expression: "item.probation.proportion",
                                      },
                                    })
                                  : _vm._e(),
                                _c("NumberInt", {
                                  staticClass: "value",
                                  attrs: {
                                    suffix: "元",
                                    "decimal-separator": "",
                                    "is-fill-zero": "",
                                    precision: 2,
                                    disabled:
                                      item.probation.way === "proportion",
                                  },
                                  on: {
                                    blur: function ($event) {
                                      return _vm.probationAmountChange(
                                        item.probation.amount,
                                        item.formal.amount,
                                        item
                                      )
                                    },
                                  },
                                  model: {
                                    value: item.probation.amount,
                                    callback: function ($$v) {
                                      _vm.$set(item.probation, "amount", $$v)
                                    },
                                    expression: "item.probation.amount",
                                  },
                                }),
                              ],
                              1
                            ),
                          ])
                        : _vm._e(),
                    ]
                  ),
                ]
              }),
            ],
            2
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }